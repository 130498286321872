<template>
	<table>
		<tr>
      		<td :colspan="treeData.children ? treeData.children.length * 2 : 1" 
			  	:class="{ parentLevel: treeData.children && checkChildrenFilters(treeData.children), 
				  		  extend: treeData.children && treeData.children.length > 0 && treeData.extend && checkChildrenFilters(treeData.children) }">
				<div :class="{ node: true }">
				<!-- PARENT NODE -->
					<div class="box-tbf compay-box" @click="$emit('click-node', treeData)" v-if="treeData.type == 'company'">
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{$t('tree-chart.company')}}
								</div>
								<div class="box-actions">
									<v-popover class="counter" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
										<div class="counter"><span class="text">{{ treeData.company_people }}</span></div>

										<template slot="popover">
											<div class="simple-text">{{ $tc('tree-chart.employees',treeData.company_people) }}</div>
										</template>
									</v-popover>
									<div v-if="$auth.user().rights.admins_crud" class="dropdown menu-animated simple-box">
										<div class="open-menu" id="dropdownCompany" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownCompany">
											<div class="menu-items">
												<div class="btn-animated add-action" @click="$router.push({name: 'user-create'})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
															<icon-user />

														<template slot="popover">
															<div class="simple-text">{{ $t('tree-chart.add_user') }}</div>
														</template>
													</v-popover>
												</div>
												<div class="btn-animated edit-action" @click="showModal('department', {from: 'chart'})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
														<icon-department />

														<template slot="popover">
															<div class="simple-text">{{ $t('tree-chart.add_department') }}</div>
														</template>
													</v-popover>
												</div>
												<div class="btn-animated edit-action" @click="showModal('company', {id: treeData.id, from: 'chart', name: treeData.company_name, avatar: treeData.company_avatar})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
														<icon-edit />

														<template slot="popover">
															<div class="simple-text">{{ $t('tree-chart.edit_company') }}</div>
														</template>
													</v-popover>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="title-company">
								<div class="avatar">
									<img :src="treeData.company_avatar" class="avatar-user" v-if="treeData.company_avatar">
									<div class="user-circle" v-else>
										<icon-user/>
									</div>
								</div>
								<div class="data">
									<div class="name">{{ treeData.company_name }}</div>
								</div>
							</div>
						</div>
					</div>

					<!-- CHILD LIGHT GREY -->
					<div class="box-tbf department-box" v-if="treeData.type == 'department'">
						<div class="avatar-wrapper" v-if="treeData.responsible">
							<div class="avatar">
								<v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
									<img :src="treeData.responsible.avatar" class="avatar-user" v-if="treeData.responsible.avatar">
									<div class="user-circle" v-else>
										<icon-user/>
									</div>

									<template slot="popover">
										<div class="simple-text">{{ treeData.responsible.first_name + ' ' +  treeData.responsible.last_name }}</div>
									</template>
								</v-popover>						
							</div>
						</div>

						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{$t('tree-chart.department')}}
								</div>
								<div class="box-actions">
									<v-popover class="counter" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
										<div class="counter"><span class="text">{{ treeData.people }}</span></div>

										<template slot="popover">
											<div class="simple-text">{{ $tc('tree-chart.employees',treeData.people) }}</div>
										</template>
									</v-popover>
									<div v-if="$auth.user().rights.admins_crud" class="dropdown menu-animated simple-box">
										<div class="open-menu" :id="'dropdownProcedure'+treeData.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownProcedure'+treeData.id">
											<div class="menu-items">
												<div class="btn-animated add-action" @click="showModal('user', {department_id: treeData.id, department_slug: treeData.slug, from: 'chart'})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
															<icon-user />

														<template slot="popover">
															<div class="simple-text">{{ $t('tree-chart.add_user') }}</div>
														</template>
													</v-popover>
												</div>
												<div class="btn-animated edit-action" @click="showModal('department', {id: treeData.id, parent_id: treeData.id, from: 'chart'})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
														<icon-department />

														<template slot="popover">
															<div class="simple-text">{{ $t('tree-chart.add_department') }}</div>
														</template>
													</v-popover>
												</div>
												<div class="btn-animated edit-action" @click="showModal('department', {id: treeData.id, parent_id: treeData.department_id, from: 'chart', edited: true, slug: treeData.slug})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
														<icon-edit />

														<template slot="popover">
															<div class="simple-text">{{ $t('tree-chart.edit_department') }}</div>
														</template>
													</v-popover>
												</div>
												<div class="btn-animated delete-action" @click="showModal('delete', {type: 'department', model: {slug: treeData.slug}, from: 'chart'})">
													<v-popover :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
														<icon-trash />

														<template slot="popover">
															<div class="simple-text">{{ $t('general.delete') }}</div>
														</template>
													</v-popover>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="title-department">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :disabled="!treeData.description || $resize && $mq.below(780)" :delay="{show: 200, hide: 0}">
									<span class="text" @click="goToDepartment(treeData.slug)">{{ treeData.name }}</span>

									<template slot="popover">
										<div class="simple-text">{{ treeData.description }}</div>
									</template>
								</v-popover>
							</div>
						</div>
					</div>

					<div class="box-tbf user-box" v-if="treeData.type == 'user'">
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{$t('tree-chart.employee')}}
								</div>

								<div v-if="promises.ready" class="promise-status">
									<v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="circle-status" v-if="promises.users[treeData.id]" :class="{red: ['not_completed', 'not_set'].includes(promises.users[treeData.id].status), yellow: ['pending'].includes(promises.users[treeData.id].status), green: ['completed'].includes(promises.users[treeData.id].status), grey: ['no_key_result', 'not_working'].includes(promises.users[treeData.id].status)}"></div>
										<div v-else class="circle-status grey"></div>

										<template slot="popover">
											<div class="simple-text">{{ promises.users[treeData.id] ? $t(`promise_objectives.status.${promises.users[treeData.id].status}`) : $t(`promise_objectives.status.not_working`) }}</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div class="user-data">
								<div class="avatar">
									<img :src="treeData.avatar" class="avatar-user" v-if="treeData.avatar">
									<div class="user-circle" v-else>
										<icon-user/>
									</div>
								</div>
								<div class="data">
									<v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="name" :class="{link: treeData.rights.view}" @click="treeData.rights.view ? showModal('sidebar_org', {slug: treeData.slug}) : ''">{{ treeData.first_name + ' ' +  treeData.last_name }}</div>
										<div class="roles"><span v-for="(role, index) in treeData.roles"><span>{{role.name}}</span><span v-if="index+1 < treeData.roles.length">, </span></span></div>

										<template slot="popover">
											<div class="title">{{ treeData.first_name + ' ' +  treeData.last_name }}</div>
											<div class="info-text"><span v-for="(role, index) in treeData.roles"><span>{{role.name}}</span><span v-if="index+1 < treeData.roles.length">, </span></span></div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
					</div>

					<div class="box-tbf vacant-job-box" v-if="treeData.type == 'vacant_job'">
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{$t('tree-chart.vacant_job')}}
								</div>
							</div>

							<div class="user-data">
								<div class="data">
									<v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
										<div class="name link" @click="showModal('sidebar_vacant_job', {slug: treeData.slug})">{{ treeData.name }}</div>
										<div class="roles"><span v-for="(role, index) in treeData.roles"><span>{{role.name}}</span><span v-if="index+1 < treeData.roles.length">, </span></span></div>

										<template slot="popover">
											<div class="title">{{ treeData.name }}</div>
											<div class="info-text">{{ treeData.description }}</div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="extend-handle-tbf" :class="{open: !parentOpen}" @click="toggleExtend(treeData)" v-if="treeData.children && treeData.children.length > 0 && checkChildrenFilters(treeData.children)"></div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend && checkChildrenFilters(treeData.children)" class="children-row-wrapper">
			<td v-for="(children, index) in treeData.children"
				:key="index"
				colspan="2"
				class="childLevel"
				v-if="checkUserFilters(children)"
			>
				<TreeChart :json="children" :zoomPage="zoomPage" :filters="filters" @click-node="$emit('click-node', $event)" :promises="promises"/>
			</td>
		</tr>
	</table>
</template>

<script>
	import EditDotsIcon from "../../Icons/EditDots";
	import IconUser from '../../Icons/User'
	import IconDepartment from '../../Icons/Department'
	import IconTrash from '../../Icons/Trash'
	import IconEdit from '../../Icons/Edit'

export default {
	data() {
		return {
			treeData: {},
			parentOpen: false,
			filtersUser: {}
		};
	},
  	props: {
  		json: Object,
  		zoomPage: Number,
  		filters: Object,
  		promises: Object
  	},
  	name: "TreeChart",
	components: {
		EditDotsIcon,
		IconUser,
		IconDepartment,
		IconTrash,
		IconEdit
	},
	watch: {
		json: {
			handler: function(Props) {
				let extendKey = function(jsonData) {
					var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];

					if(jsonExtend.find(el => el.id == jsonData.id)){
						jsonData.extend = jsonExtend.find(el => el.id == jsonData.id).extend
					}else{
						jsonData.extend = true
						jsonExtend.push({ id: jsonData.id, extend: jsonData.extend })
					}
					localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

					return jsonData;
				};
				if(Props) {
					if(typeof Props === 'object'){
						this.treeData = extendKey(Props);
					}
				}
			},
			immediate: true,
		},
		filters: {
			immediate: true,
			deep: true,
			handler (val) {
				this.filtersUser = val
			}
		}
	},
	mounted(){
		this.$root.$on('toggleExtendAll', (valueExtend) => {
			this.treeData = extendKey2(this.treeData, valueExtend);
			this.$forceUpdate()

			var noCountExntend = 0
			localStorage.setItem('numberCount', (noCountExntend ? noCountExntend = parseInt(noCountExntend) + 1 : 1))
		})

		function extendKey2(jsonData, extendValue) {
			var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];

			jsonData.extend = extendValue
			if(jsonExtend.find(el => el.id == jsonData.id)){
				jsonExtend.find(el => el.id == jsonData.id).extend = jsonData.extend
			}else{
				jsonExtend.push({ id: jsonData.id, extend: jsonData.extend })
			}
			localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

			return jsonData;
		};

		var noCount = localStorage.getItem('numberCount')
		localStorage.setItem('numberCount', (noCount ? noCount = parseInt(noCount) + 1 : 1))

		if(noCount == localStorage.getItem('numberCountExtend')){
			this.centerOrg()
		}
	},
  	methods: {
		goToDepartment(slug){
			this.$router.push({ name: 'department', params: {slug:slug} })
		},
		toggleExtend: function(treeData) {
			var jsonExtend = localStorage.getItem('jsonExtend') ? JSON.parse(localStorage.getItem('jsonExtend')) : [];
			treeData.extend = !treeData.extend;
			jsonExtend.find(el => el.id == treeData.id).extend = treeData.extend
			localStorage.setItem('jsonExtend', JSON.stringify(jsonExtend))

			this.parentOpen = !this.parentOpen;
			this.$forceUpdate();
		},
		showModal(type, data = false){
			this.$root.$emit('open_modal_full_template', type, data);
		},
		centerOrg(){
			var calcWidth = ($('.scrollable-chart .center-box .parentLevel').first().children('.node').position().left * this.zoomPage) - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel').first().children('.node').width() / 2) * this.zoomPage) + 30

			$('.scrollable-chart').animate({
				scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
				scrollLeft: calcWidth
			}, 300);
		},
		checkUserFilters(children){
			if(children.type == 'user' || children.type == 'vacant_job'){
				//Check Filter Role
				var existRole = true
				if(this.filtersUser.role.length){
					existRole = false
					children.roles.map(el => {
						if(this.filtersUser.role.some(role => role.id == el.id)){
							existRole = true
						}
					})
				}
				//Check Filter Workstation
				var existWorkstation = true
				if(this.filtersUser.workstation.length){
					existWorkstation = false
					if(this.filtersUser.workstation.some(workstation => workstation.id == children.workstation_id)){
						existWorkstation = true
					}
				}
				//Check Filter Work Schedule
				var existWorkSchedule = true
				if(this.filtersUser.work_schedule.length){
					existWorkSchedule = false
					if(this.filtersUser.work_schedule.some(work_schedule => work_schedule.id == children.work_schedule_id)){
						existWorkSchedule = true
					}
				}
				//Check Filter Skill
				var existSkill = true
				if(this.filtersUser.skill.length){
					existSkill = false
					children.skills_ids.map(el => {
						if(this.filtersUser.skill.some(skill => skill.id == el)){
							existSkill = true
						}
					})
				}
				//Check Filter Language
				var existLanguage = true
				if(this.filtersUser.language.length){
					existLanguage = false
					children.languages_ids.map(el => {
						if(this.filtersUser.language.some(language => language.id == el)){
							existLanguage = true
						}
					})
				}

				if(existRole && existWorkstation && existWorkSchedule && existSkill && existLanguage){
					return true
				}else{
					return false
				}
			}else{
				return true
			}
		},
		checkChildrenFilters(childrens){
			var canView = false

			childrens.every(el => {
				canView = this.checkUserFilters(el)
			})

			return canView
		}
  	}
};
</script>
